import React from "react"

import Layout from "../components/Layouts/secondaryPage"
import Finish from "../components/Finish"
import { CustomLogo } from "../components/Headers/CustomLogo"
import { RedHalvaWithSovcombank } from "../components/Headers/Logos/RedHalvaWithSovcombank/RedHalvaWithSovcombank"

const head = "Спасибо!"
const subHead = "Ваша заявка отправлена"
const text = "В ближайшее время с Вами свяжутся специалисты нашего Банка."

export default function Page() {
  return (
    <Layout>
      <CustomLogo phones={["8 (800) 100-10-20"]} withButton={false} Logo={RedHalvaWithSovcombank} />
      <Finish head={head} subHead={subHead} text={text} />
    </Layout>
  )
}
